<template>
    <re-page
        show-background
        :is-centered="true"
        :center-title="true"
        :page-title="title || $t('monolith.global.login')"
        max-width="sm"
    >
        <template v-if="!isEmailSent">
            <h2 class="text-center">
                {{ subtitle || $t('monolith.login.welcome') }}
            </h2>
            <form
                ref="loginForm"
                :action="loginUrl"
                method="POST"
                @submit.prevent="$refs.emailInput?.validate() && $refs.loginForm.submit()"
            >
                <div class="row q-col-gutter-md">
                    <div class="col-xs-12 col-sm-9">
                        <re-input
                            ref="emailInput"
                            v-model="email"
                            name="email"
                            type="email"
                            required
                            :placeholder="$t('monolith.login.email')"
                        />
                    </div>
                    <div class="col">
                        <re-button
                            :label="$t('monolith.global.login')"
                            :disable="$refs.emailInput?.hasError"
                            type="submit"
                            color="main"
                            xs-full-width
                        />
                    </div>
                </div>
            </form>
        </template>
        <!-- sent mail -->
        <div v-else>
            <div class="text-center bg-white shadow-1 q-pa-md">
                <q-avatar class="q-ma-md checkbox" icon="mdi-check-bold" size="xl" />
                <h3 class="q-pb-lg">{{ $t('monolith.login.msg.email-sent_title') }}</h3>
                <p class="text-body1">{{ $t('monolith.login.msg.email-sent') }}</p>
                <p class="text-bold">{{ $route.query.email }}</p>
                <p class="text-body1">{{ $t('monolith.login.msg.email-sent_message') }}</p>
            </div>
            <re-button
                class="q-mt-lg"
                :to="{ params: { action: 'login' } }"
                color="main"
                icon="mdi-arrow-left"
                :label="$t('common.previous')"
            />
        </div>
        <!-- sent mail -->
        <re-banner v-if="!!notificationType" :color="notificationType" class="q-mt-md">
            {{
                $t('monolith.login.msg.' + $route.query.msg, {
                    env: $route.query.env,
                })
            }}
        </re-banner>
    </re-page>
</template>

<script>
import utils from '@/shared/plugins/utils'
import ReBanner from '@/components/elements/ReBanner.vue'
import { useHead } from '@unhead/vue'

export default {
    name: 'LogIn',
    components: {
        ReBanner,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        subtitle: {
            type: String,
            default: '',
        },
    },
    setup() {
        useHead({
            title: 'Log In',
            titleTemplate: '%s | Rock.estate Valuation App',
        })
    },
    data() {
        return {
            email: '',
        }
    },
    computed: {
        isEmailSent() {
            return this.$route.query.msg === 'email-sent'
        },
        loginUrl: function () {
            let redirect_query_param = this.$route.query.r ? `&r=${this.$route.query.r}` : ''
            return `${utils.urlJoin(this.$config.AUTH_API_URL, 'enter')}?lang=${
                this.$route.params.lang
            }${redirect_query_param}`
        },
        notificationType() {
            return {
                logout: 'positive',
                'email-not-authorized': 'negative',
                'server-error': 'negative',
                'invalid-token': 'warning',
                'session-expired': 'warning',
                'wrong-environment': 'warning',
                'invalid-email-address': 'negative',
            }[this.$route.query.msg]
        },
    },
}
</script>

<style lang="scss" scoped>
.checkbox {
    background-color: $positive-bground;
    color: $positive;
}
</style>
