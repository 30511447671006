<template>
    <q-card class="fit q-mb-md" square>
        <q-card-section class="q-col-gutter-md row wrap justify-start items-start content-start">
            <div class="">
                <q-avatar class="bg-primary-light">
                    <re-icon :svg-name="bt_icon(props.row.building_type)" size="xs" color="primary" />
                </q-avatar>
            </div>
            <div class="col-grow">
                <h6 class="text-dark">
                    {{ props.row.address.streetname }}&nbsp;{{ props.row.address.streetnumber }},
                    <br />
                    {{ props.row.address.postalcode }}&nbsp;{{ props.row.address.municipality }}
                </h6>
            </div>
            <div>
                <q-chip :color="statusChipColor(props.row.status)">
                    {{ $t(`status.${props.row.status}`) }}
                </q-chip>
            </div>
            <div class="text-caption text-weight-medium text-dark">
                <div class="info-line">
                    <span class="info-line-item">
                        <span class="text-blue-grey">
                            {{ $t('monolith.shared.customer_ref') }}{{ $t('punctuation.:') }}&nbsp;
                        </span>
                        <span>{{ props.row.customer_ref }}</span>
                    </span>
                    <span class="info-line-separator q-mx-xs" />
                    <span class="info-line-item">
                        <span class="text-blue-grey">
                            {{ $t('monolith.shared.request_ref') }}{{ $t('punctuation.:') }}&nbsp;
                        </span>
                        <span>{{ props.row.valuation_request_ref }}</span>
                    </span>
                </div>
                <div class="info-line">
                    <span class="info-line-item">
                        <span class="text-blue-grey">
                            {{ $t('monolith.shared.created_at') }}{{ $t('punctuation.:') }}&nbsp;
                        </span>
                        <span>{{ $format.date(props.row.created_at) }}</span>
                    </span>
                    <span class="info-line-separator q-mx-xs" />
                    <span class="info-line-item">
                        <span class="text-blue-grey">
                            {{ $t('monolith.shared.last_modified') }}{{ $t('punctuation.:') }}&nbsp;
                        </span>
                        <span>{{ $format.date(props.row.modified_at) }}</span>
                    </span>
                </div>
            </div>
            <div v-if="hasBadges" class="tags-container">
                <q-badge
                    v-if="
                        needsBorrowerEmail(props.row) &&
                        (hasRole('dispatcher', 'ovm') || props.row.owner.is_self)
                    "
                    color="warning"
                >
                    {{ $t('monolith.dashboard.badges.needs_borrower_email') }}
                </q-badge>
                <q-badge v-if="needsAppointmentConfirmation(props.row) && isTypeDispatcher" color="warning">
                    {{ $t('monolith.dashboard.badges.needs_appointment_confirmation') }}
                </q-badge>
                <q-badge
                    v-if="needsBorrowerAction(props.row) && hasRole('dispatcher', 'ovm')"
                    color="warning"
                >
                    {{ $t('monolith.dashboard.badges.needs_borrower_action') }}
                </q-badge>
                <q-badge
                    v-if="
                        isAppointmentConfirmed(props.row) &&
                        (hasRole('dispatcher', 'ovm') || props.row.owner.is_self || props.row.valuer?.is_self)
                    "
                    type="is-success is-light"
                >
                    {{ $t('monolith.dashboard.badges.appointment_confirmed') }}
                </q-badge>
                <cell-valuer v-bind="props" dense />
            </div>
            <div class="col-12">
                <action-buttons
                    :row="props.row"
                    @update="emit('update', [props.row.valuation_request_ref, $event])"
                />
            </div>
        </q-card-section>
    </q-card>
</template>
<script setup>
import ActionButtons from './ActionButtons.vue'
import CellValuer from './TableCells/CellValuer.vue'
import { inject, computed } from 'vue'

const props = defineProps({
    row: {
        type: Object,
        required: true,
    },
})

const curUser = inject('curUser')

const bt_icon = (bt) => {
    switch (bt) {
        case 'apartment':
            return 'apartment'
        case 'new_house':
        case 'new_apartment':
            return 'new-construction'
        case 'house':
        default:
            return 'home'
    }
}

const statusChipColor = (status) => {
    switch (status) {
        case 'awaiting-onsite':
        case 'draft':
            return 'warning'
        case 'cancelled':
            return 'negative'
        default:
            return 'positive'
    }
}

const hasRole = (r, m) => curUser.hasRole(r, m)

const needsBorrowerEmail = (row) => {
    return !!(row.status === 'submitted' && row.ovm_status === 'borrower-registration-pending')
}

const needsBorrowerAction = (row) => {
    return !!(
        row.status === 'submitted' &&
        ['appointment-pending', 'payment-pending', 'borrower-details'].includes(row.ovm_status)
    )
}

const needsAppointmentConfirmation = (row) => {
    return !!(row.status === 'submitted' && row.ovm_status === 'confirmation-pending')
}

const isAppointmentConfirmed = (row) => {
    return !!(row.status === 'submitted' && row.ovm_status === 'valuation-pending')
}

const hasBadges = computed(() => {
    return (
        needsBorrowerEmail(props.row) ||
        needsBorrowerAction(props.row) ||
        needsAppointmentConfirmation(props.row) ||
        isAppointmentConfirmed(props.row)
    )
})

const isTypeDispatcher = computed(() => curUser.hasRole('dispatcher', props.row.valuation_type))
</script>
<style lang="scss" scoped>
.info-line-separator {
    &:before {
        content: '•';
        color: $secondary;
    }
}

.tags-container div {
    margin-right: 1em;
}
</style>
