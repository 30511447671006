<template>
    <div id="sidemenu">
        <q-drawer
            v-model="menuOpen"
            class="sidemenu"
            show-if-above
            :mini="isMenuMini"
            mini-to-overlay
            :mini-width="65"
            :breakpoint="599"
            :width="$q.screen.lt.sm ? $q.screen.width : 300"
            side="left"
            bordered
            @mouseover="isMenuMini = false"
            @mouseout="isMenuMini = true"
        >
            <q-list v-if="$q.screen.xs">
                <q-item clickable @click="menuOpen = false">
                    <q-item-section />
                    <q-item-section avatar>
                        <re-icon size="xl" color="primary" name="mdi-backburger" />
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section avatar>
                        <re-icon name="mdi-account" />
                    </q-item-section>
                    <q-item-section>
                        {{ curUser?.data.email }}
                    </q-item-section>
                </q-item>
            </q-list>
            <div v-if="$q.screen.xs" class="q-pa-md">
                <navbar-items size="md" />
            </div>
            <q-list>
                <q-item
                    v-for="item in menuItems.filter((i) => i.show !== false)"
                    :key="item.label"
                    clickable
                    :active="$route.name === item.route"
                    :to="{ name: item.route }"
                    class="sidemenu-item"
                    @click="item.click"
                >
                    <q-item-section avatar>
                        <re-icon
                            :svg-name="item.svgIcon"
                            :name="item.icon"
                            :indicator="item.indicator"
                            :self-center="false"
                            color="inherit"
                        ></re-icon>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label :class="{ 'text-weight-bold': $route.name === item.route }">
                            {{ $t(item.label) }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>
        </q-drawer>
    </div>
</template>
<script setup>
import { ref, inject, computed } from 'vue'

import NavbarItems from '../NavbarItems.vue'

const curUser = inject('curUser')
const config = inject('config')

const menuOpen = defineModel('menuOpen', { type: Boolean })

const isMenuMini = ref(true)

const menuItems = computed(() => [
    {
        svgIcon: 'apps',
        label: 'section.apps',
        route: 'appDashboard',
        show: curUser.submitableModules.length > 1,
    },
    {
        svgIcon: 'monitor',
        label: 'section.dashboard',
        route: 'dashboard',
    },
    {
        icon: 'mdi-account',
        label: 'monolith.shared.valuer',
        route: 'valuation.valuer-settings',
        show: curUser.is.ovmValuer || curUser.is.oepcValuer,
    },
    {
        icon: 'mdi-account-supervisor',
        label: 'section.dispatcher',
        route: 'valuation.dispatcher-settings',
        show: curUser.is.ovmDispatcher,
    },
    {
        svgIcon: 'help',
        label: 'section.helpcenter',
        route: 'support',
    },
    {
        icon: 'mdi-account-multiple',
        label: 'section.user-admin',
        route: 'admin',
        show: curUser.hasRole('admin'),
    },
    {
        icon: 'mdi-cog',
        label: 'section.configuration',
        route: 'configuration',
        show: curUser.hasRole('developer'),
        indicator: !config.isDefault,
    },
    // NOTE: this override will apply even if current user has dev permission
    // we must imperatively display this warning to avoid debugging headaches
    {
        icon: 'mdi-cog',
        label: 'TODO: Reset config',
        show: !curUser.hasRole('developer') && !config.isDefault,
        indicator: true,
        click: () => {
            localStorage.setItem('re-local-config', '{}')
        },
    },
    {
        icon: 'mdi-card-bulleted',
        label: 'playground',
        show: curUser.hasRole('developer'),
        route: 'playground',
    },
])

// // TODO: move this to beforeEnter guard on Support:
// // const from_query_args = computed(function () {
// //     if (!this.$route) return {}

// //     let q = {}
// //     if (this.$route.name === null) {
// //         q.from_module = '-'
// //     } else if (this.$route.name?.startsWith('ers.')) {
// //         q.from_module = 'ers'
// //         q.valuation_request_ref = this.$route.query.ref
// //     } else if (this.$route.name?.startsWith('valuation.')) {
// //         if (this.$route.query.valuation_type) {
// //             q.from_module = this.$route.query.valuation_type
// //         } else {
// //             q.from_module = this.curUser.submitableValuations[0]
// //         }
// //         q.valuation_request_ref = this.$route.query.valuation_request_ref
// //     }
// //     return q
// // })
</script>
<style lang="scss" scoped>
@media print {
    #sidemenu {
        display: none;
    }
}

.sidemenu-item {
    color: $secondary-light !important;
    margin: 5px;
    border-radius: $button-border-radius;
    width: calc(100% - 10px);

    &:hover {
        color: $secondary !important;
    }
}

:deep(.q-item--active) {
    background-color: $primary-bground;
    color: $primary !important;
    font-weight: 900;

    &:hover {
        color: $primary !important;
    }
}
</style>
