<template>
    <q-banner rounded class="banner row" :class="[`banner_${props.color}`, 'q-pa-md']" v-bind="$attrs">
        <template v-if="!props.noIcon" #avatar>
            <q-avatar :icon="iconName" size="xl" />
        </template>
        <h6 v-if="props.title" class="text-bold q-pb-sm">{{ props.title }}</h6>
        <slot></slot>
        <template v-if="!!$slots['action']" #action>
            <div class="q-mt-md">
                <slot name="action"></slot>
            </div>
        </template>
    </q-banner>
</template>
<script setup>
import { computed } from 'vue'

const props = defineProps({
    color: {
        type: String,
        validator(value) {
            // The value must match one of these strings
            return ['positive', 'warning', 'negative', 'info', 'info-light'].includes(value)
        },
        default: 'info',
    },
    noIcon: { type: Boolean, default: false },
    title: { type: String, default: null },
})

const iconName = computed(() => {
    return {
        positive: 'mdi-check',
        warning: 'mdi-alert',
        negative: 'mdi-alert-octagon',
        info: 'mdi-lightbulb-outline',
        'info-light': 'mdi-lightbulb-outline',
    }[props.color]
})
</script>

<style scoped lang="scss">
/* Q-banner is adding text-body2 class on default content, so we disable some properties */
.banner {
    min-height: fit-content;

    :deep(.text-body2) {
        font-weight: 500;
    }
    :deep(.q-banner__content p:last-child) {
        margin: 0;
    }
    :deep(.q-banner__avatar) {
        align-self: center !important;
    }

    &_positive {
        background-color: #edf9f4;
        :deep(.q-banner__content) {
            color: #2b7755;
            h6 {
                color: #2b7755;
            }
            a {
                color: #2b7755 !important;
                text-decoration: underline !important;
            }
        }
        :deep(.q-icon) {
            color: #48c78e;
        }
        :deep(.q-avatar) {
            background-color: #daf4e8;
        }
    }

    &_info-light {
        background-color: lighten(#b0d4fc, 10%);
        :deep(.q-banner__content) {
            color: #798da6;
            h6 {
                color: #798da6;
            }
            a {
                color: #798da6 !important;
                text-decoration: underline !important;
            }
        }
        :deep(.q-icon) {
            color: #009cff;
        }
        :deep(.q-avatar) {
            background-color: #c7d9f9 !important;
        }
    }

    &_info {
        background-color: #e3ebf9;
        :deep(.q-banner__content) {
            color: #798da6;
            h6 {
                color: #798da6;
            }
            a {
                color: #798da6 !important;
                text-decoration: underline !important;
            }
        }
        :deep(.q-icon) {
            color: #009cff;
        }
        :deep(.q-avatar) {
            background-color: #c7d9f9 !important;
        }
    }
    &_warning {
        background-color: #fff6e5;
        :deep(.q-banner__content) {
            color: #996600;
            h6 {
                color: #996600;
            }
            a {
                color: #996600 !important;
                text-decoration: underline !important;
            }
        }
        :deep(.q-banner__content a) {
            color: #996600 !important;
            text-decoration: underline !important;
        }
        :deep(.q-icon) {
            color: #ffaa00;
        }
        :deep(.q-avatar) {
            background-color: #ffeecc;
        }
    }
    &_negative {
        background-color: #feecf0;
        :deep(.q-banner__content) {
            color: #962a40;
            h6 {
                color: #962a40;
            }
            a {
                color: #962a40 !important;
                text-decoration: underline !important;
            }
        }
        :deep(.q-icon) {
            color: #f14668;
        }
        :deep(.q-avatar) {
            background-color: #fcdae1;
        }
    }
}
</style>
