<template>
    <div class="re-field" :class="props.class">
        <div v-if="$slots.label || props.label" class="label all-pointer-events">
            <slot name="label">
                <p class="row no-wrap">
                    {{ props.label !== true ? props.label : '&nbsp;' }}
                    <re-tooltip v-if="props.tooltip" :label="props.tooltip" />
                </p>
            </slot>
        </div>
        <q-field
            v-if="!props.noQField"
            borderless
            hide-bottom-space
            :bottom-slots="!!$slots.hint"
            :rules="effectiveRules"
            :no-error-icon="props.noErrorIcon"
            v-bind="$attrs"
            @input="(val) => console.warn('ReField: input event emitted', val)"
        >
            <!-- Passing all other parent slots directly: -->
            <template v-for="slotName in Object.keys($slots)" :key="slotName" #[slotName]="slotProps">
                <slot v-if="slotName != 'label'" :name="slotName" v-bind="slotProps ?? {}"></slot>
            </template>
        </q-field>
        <slot
            v-for="slotName in Object.keys($slots).filter((slotName) => slotName !== 'label')"
            v-else
            :key="slotName"
            :name="slotName"
        ></slot>
    </div>
</template>
<script setup>
import { computed } from 'vue'
import reRules from '@/shared/plugins/rules'

// This is needed to prevent Quasar from adding the $attrs to the root div instead of the q-field:
defineOptions({ inheritAttrs: false })

const props = defineProps({
    label: {
        type: [String, Boolean],
        default: '',
    },
    tooltip: {
        type: String,
        default: '',
    },
    noQField: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    rules: {
        type: Array,
        default: () => [],
    },
    noErrorIcon: {
        type: Boolean,
        default: false,
    },
    class: {
        type: [String, Object],
        default: undefined,
    },
})

const effectiveRules = computed(() => {
    const rules = [...props.rules]
    if (props.required) rules.push(reRules.required)
    return rules
})
</script>
<style lang="scss" scoped>
.re-field {
    .label {
        max-height: calc(1.2em + 8px);
        height: calc(1.2em + 8px);

        p {
            font-size: 1em;
            text-wrap: nowrap;
            line-height: 1.2em;
            color: $secondary;
            font-weight: 500;
        }
    }

    // TODO: implement nesting and sizing of components within q-field in a cleaner way to avoid having to do this:
    :deep(.q-field__control-container .q-field__native) {
        padding: 0;
    }

    // :deep(.q-field__bottom) {
    //     position: absolute;
    // }
}
</style>
