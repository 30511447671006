<template>
    <re-page
        max-width="xl"
        page-title="Dashboard"
        :tooltip="$t('monolith.dashboard.title_tooltip')"
        class="bg-bground-color"
        main-content-class="q-pa-md"
    >
        <q-expansion-item
            v-if="curUser.is.dvmDispatcher"
            :label="$t('monolith.dashboard.valuers_workload')"
            header-class="text-primary text-h5"
            class="q-my-xl shadow-2"
        >
            <valuer-workload
                class="q-pa-md bg-white"
                :valuers="dvmValuers.data || []"
                :loading="dvmValuers.isBusy"
                :requests="requestList?.data.filter((row) => row.valuation_type === 'dvm')"
            />
        </q-expansion-item>
        <div v-if="curUser.is.dvmDispatcher" class="row justify-start items-start re-gutter re-block-mb">
            <re-button
                v-if="reportLink && !reportLoading"
                :href="reportLink"
                :download="`${reportFileName}.csv`"
                icon="mdi-download"
                size="md"
                :label="$t('monolith.dashboard.download_report')"
            />
            <re-button
                :loading="reportLoading"
                size="md"
                color="main"
                :icon="reportLoading || reportLink ? 'mdi-autorenew' : 'mdi-download'"
                :disable="reportLoading"
                :label="
                    $t(
                        `monolith.dashboard.${reportLoading ? 'report_loading' : reportLink ? 'refresh_report' : 'create_report'}`
                    )
                "
                @click="generateReport"
            />
        </div>
        <div class="re-table-filters q-mb-xl row justify-between items-center q-col-gutter-md">
            <re-multi-choice
                v-model="requestList.timeFilter"
                :label="null"
                :disable="!!requestList.customerRefFilter || !!requestList.valReqsFilter"
                :options="[
                    { label: $t(`monolith.dashboard.last_year`), value: 'last_year' },
                    { label: $t(`monolith.dashboard.last_month`), value: 'last_month' },
                ]"
                size="md"
                :color="requestList.valReqsFilter ? 'warning' : undefined"
                class="col-xs-12 col-sm-grow"
            />
            <re-multi-choice
                v-if="showAllRequestsTab"
                v-model="requestList.isUserRequests"
                aria-label="requests"
                :label="null"
                :options="[
                    { label: $t(`monolith.dashboard.my_requests`), value: true },
                    {
                        label: $t(`monolith.dashboard.all_requests`),
                        value: false,
                    },
                ]"
                :color="requestList.valReqsFilter ? 'warning' : undefined"
                size="md"
                class="col-xs-12 col-sm-grow"
            />
            <re-input
                v-if="showCustomerRefField"
                v-model="tempCustomerRefFilter"
                placeholder="customer ref"
                size="md"
            >
                <template #embed>
                    <re-button
                        icon="mdi-arrow-right"
                        type="submit"
                        color="main"
                        stretch
                        :disable="tempCustomerRefFilter == requestList.customerRefFilter"
                        :loading="requestList.isBusy"
                        size="md"
                        @click="
                            () => {
                                requestList.isUserRequests = tempCustomerRefFilter === ''
                                requestList.customerRefFilter = tempCustomerRefFilter
                            }
                        "
                    />
                </template>
            </re-input>

            <!-- input section -->
            <!-- <div v-if="curUser.accessibleModules.length > 1" class="request_type_filter"> -->
            <div v-if="isModuleFilterEnabled">
                <q-checkbox v-for="m in curUser.accessibleModules" :key="m" v-model="moduleFilter" :val="m">
                    {{ $t(`value.short.${m}`) }}
                </q-checkbox>
            </div>
            <div class="gt-sm col"></div>
            <!-- per-page select -->
            <re-select
                v-model="pagination.rowsPerPage"
                :options="
                    [5, 10, 25, 50].map((i) => ({
                        label: `${i} ${$t('monolith.shared.per_page')}`,
                        value: i,
                    }))
                "
                dense
                size="md"
                class="col-xs-12 col-sm-grow"
            />
        </div>
        <q-table
            v-if="tableData"
            v-model:pagination="pagination"
            :rows-per-page-options="[5, 10, 25, 50]"
            :rows="tableData"
            row-key="valuation_request_ref"
            :columns="columns"
            :visible-columns="columns.filter((c) => c.visible).map((c) => c.name)"
            :grid="$q.screen.lt.md"
            wrap-cells
            :dense="$q.screen.md"
            :loading="requestList.isBusy"
            :filter-method="filterRequests"
            :filter="filters"
            color="primary"
            class="sticky-first-column request-table"
            flat
            no-data-label="No requests"
            no-results-label="No requests matching your settings"
        >
            <template v-if="!tableData?.length" #loading>
                <re-loading />
            </template>

            <!--
            <template v-if="column.searchable && !column.date" #searchable="props">
                <q-input
                    v-model="sProps.filters[sProps.column.field]"
                    :placeholder="t('search')"
                    icon="magnify"
                    size="is-small"
                />
            </template>
            <template v-else-if="column.searchable && column.date" #searchable="props">
                <span class="is-flex">
                    <q-datepicker
                        v-model="date_filters[props.column.field]"
                        :placeholder="t('search')"
                        icon="magnify"
                        size="is-small"
                        class="is-flex-grow-1"
                    />
                    <re-button
                        v-if="date_filters[props.column.field]"
                        class="clear-button"
                        @click="clearDate(props.column.field)"
                    >
                        <re-icon name="mdi-close-circle" size="sm" />
                    </re-button>
                </span>
            </template>
        -->
            <template v-if="requestList.customerRefFilter || requestList.valReqsFilter" #top>
                <re-banner color="warning" class="full-width">
                    <span v-if="requestList.customerRefFilter && requestList.valReqsFilter">
                        Displaying results filtered by valuation request and customer references.
                    </span>
                    <span v-else-if="requestList.customerRefFilter">
                        Displaying results filtered by customer references.
                    </span>
                    <span v-else>Displaying results filtered by valuation request references.</span>
                    <template #action>
                        <re-button
                            label="Clear filter"
                            size="md"
                            class="q-ml-md"
                            :to="{ query: {} }"
                            @click="clearFilters"
                        />
                    </template>
                </re-banner>
            </template>
            <!-- <template #header-cell="headerProps">
                <q-th :props="headerProps" class="header">
                    {{ headerProps.col.label }}
                </q-th>
            </template> -->
            <template #header="slotProps">
                <q-tr :props="slotProps">
                    <q-th v-for="col in slotProps.cols" :key="col.name" :props="slotProps" class="header">
                        {{ col.label }}
                    </q-th>
                </q-tr>
                <tr :props="slotProps" class="filter-header">
                    <th v-for="col in slotProps.cols" :key="col.name" :props="slotProps">
                        <re-input
                            v-if="col.searchable !== false"
                            v-model.trim="colFilters[col.name]"
                            size="sm"
                            @click.stop
                        >
                            <template #prepend>
                                <re-icon name="mdi-magnify" size="xs" />
                            </template>
                        </re-input>
                    </th>
                </tr>
            </template>
            <template #item="cardProps">
                <request-card
                    v-bind="cardProps"
                    @update="updateRow(aProps.row.valuation_request_ref, $event)"
                />
            </template>
            <!-- Customer Ref -->
            <template #body-cell-customer_ref="customerRefProps">
                <q-td data-label="customer_ref" :props="customerRefProps">
                    {{ customerRefProps.value }}
                </q-td>
            </template>
            <!-- Request Ref -->
            <template #body-cell-valuation_request_ref="vrProps">
                <q-td :props="vrProps" data-label="request_ref">
                    <cell-valuation-request v-bind="vrProps" @show-duplicates="showDuplicates(vrProps.row)" />
                </q-td>
            </template>
            <!-- Valuation Type -->
            <template #body-cell-valuation_type="vtProps">
                <q-td :props="vtProps" data-label="valuation_type">
                    <re-static :svg-name="vtProps.value" svg-subdir="request-types" justify="center">
                        {{ $t(`value.short.${vtProps.value}`) }}
                    </re-static>
                </q-td>
            </template>
            <!-- Status -->
            <template #body-cell-status="sProps">
                <q-td :props="sProps" data-label="status">
                    <cell-status v-bind="sProps" />
                </q-td>
            </template>
            <!-- Date Modified -->
            <template #body-cell-modified_at="maProps">
                <q-td :props="maProps" data-label="modified_at">
                    <span :class="dateColor(maProps.row)">
                        {{ maProps.value }}
                    </span>
                </q-td>
            </template>
            <!-- Action -->
            <template #body-cell-action="aProps">
                <q-td :props="aProps" data-label="action">
                    <action-buttons
                        :row="aProps.row"
                        @update="updateRow(aProps.row.valuation_request_ref, $event)"
                    />
                </q-td>
            </template>
            <!-- displayValuer -->
            <template #body-cell-valuer="dvProps">
                <q-td data-label="valuer" :props="dvProps">
                    <cell-valuer v-bind="dvProps" />
                </q-td>
            </template>
            <!-- Display Borrower -->
            <template #body-cell-borrower="borrowerProps">
                <q-td data-label="borrower" :props="borrowerProps">
                    <div
                        v-if="
                            borrowerProps.value &&
                            (borrowerProps.row?.owner?.is_self ||
                                curUser.hasRole('dispatcher', borrowerProps.row?.valuation_type))
                        "
                        class="row items-center"
                    >
                        <div v-if="borrowerProps.row?.referral_at == null">
                            {{ borrowerProps.value.email }}
                        </div>
                        <re-tooltip
                            v-else-if="
                                new Date(borrowerProps.value?.last_login_at) >= borrowerProps.row?.referral_at
                            "
                            class="no-wrap"
                            icon="mdi-eye-outline"
                            :label="
                                $t('monolith.dashboard.has_logged_in_after_invite', {
                                    username: borrowerProps.value.email,
                                })
                            "
                        >
                            {{ borrowerProps.value.email }}
                        </re-tooltip>
                        <re-tooltip
                            v-else
                            class="no-wrap"
                            icon="mdi-eye-off-outline"
                            :label="
                                $t('monolith.dashboard.has_not_logged_in_after_invite', {
                                    username: borrowerProps.value.email,
                                })
                            "
                        >
                            {{ borrowerProps.value.email }}
                        </re-tooltip>
                    </div>
                </q-td>
            </template>
        </q-table>
    </re-page>
</template>

<script setup>
import { ref, computed, inject, reactive } from 'vue'
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'

import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'

import RequestCard from './RequestCard.vue'
import ActionButtons from './ActionButtons.vue'

import useRequestList from '@/composables/requestList'
import useUserList from '@/composables/userList'

import { getColumns } from './table_columns.js'
import ValuerWorkload from './ValuerWorkload.vue'
import CellValuationRequest from './TableCells/CellValuationRequest.vue'
import CellStatus from './TableCells/CellStatus.vue'
import CellValuer from './TableCells/CellValuer.vue'

const curUser = inject('curUser')
const config = inject('config')
const route = useRoute()
const router = useRouter()

const requestList = useRequestList({ isUserRequests: !route.query.valuation_request_refs })
const dvmValuers = useUserList({ roles: 'valuer', modules: 'dvm' })

const reportLoading = ref(false)
const reportLink = ref(null)
const reportFileName = ref('billing-report')

const colFilters = reactive({})
const moduleFilter = ref(
    curUser.is.valuer
        ? curUser.accessibleModules.filter((m) => curUser.hasRole('valuer', m))
        : curUser.accessibleModules
)
const pagination = ref({
    sortBy: 'modified_at',
    descending: true,
    page: 1,
    rowsPerPage: 10,
})
const tempCustomerRefFilter = ref('')

const filters = computed(() => ({
    moduleFilter: moduleFilter.value,
    colFilters,
}))

// Setup:
if (route.query.valuation_request_refs) requestList.valReqsFilter = route.query.valuation_request_refs
onBeforeRouteUpdate((to, _, next) => {
    if (to.query.valuation_request_refs) {
        requestList.isUserRequests = false
        requestList.valReqsFilter = to.query.valuation_request_refs
    } else requestList.valReqsFilter = null
    next()
})

// TODO: replace by a promise on requestList
if (curUser.is.onlyBorrower) {
    requestList.onLoad((val) => {
        // Auto-redirect if only one request and is only borrower
        if (val.length == 1) {
            const row = val[0]
            // TODO: Add ERS case
            switch (row.valuation_type) {
                case 'ovm':
                    router.replace({
                        name: 'valuation.ovm.borrower',
                        query: {
                            valuation_request_ref: row.valuation_request_ref,
                        },
                    })
                    break
            }
        }
    })
}

// Computed properties:

const tableData = computed(() =>
    (requestList.data || []).filter(
        (row) =>
            !['cancelled', 'predraft'].includes(row.status) &&
            (row.ovm_status != 'payment-pending' || !curUser.hasRole('valuer', 'ovm', true))
    )
)

const columns = computed(() =>
    getColumns({ curUser, isUserRequests: requestList.isUserRequests, requests: requestList.data })
)

const colFormatFns = computed(() =>
    columns.value.reduce((acc, col) => ({ ...acc, [col.name]: col.format }), {})
)

const isModuleFilterEnabled = computed(
    // Do not filter by module when displaying duplicates:
    () => !requestList.valReqsFilter && curUser.accessibleModules.length > 1
)

const showCustomerRefField = computed(
    () => curUser.hasRole('substitute') && config.SUBSTITUTE_FEATURE_ENABLED && !config.SHOW_ALL_REQUESTS
)

const showAllRequestsTab = computed(
    () =>
        curUser.hasRole(['dispatcher', 'reporter']) ||
        (curUser.hasRole('substitute') && config.SUBSTITUTE_FEATURE_ENABLED && config.SHOW_ALL_REQUESTS)
)

const dateColor = (row) => {
    if (!curUser.is.valuer) return false
    if (row.status !== 'submitted') return false
    const difference = utils.dateDiffInBusinessDays(new Date(row.last_submission_at), new Date())
    if (difference === 1) return 'text-blue-6 text-bold'
    if (difference >= 2) return 'text-negative text-bold'
    return false
}

useHead({
    title: 'Dashboard',
    titleTemplate: '%s | Rock.estate Valuation App',
})

// Methods:

const filterRequests = (rows, { customerRefFilter, moduleFilter, colFilters }) => {
    return rows.filter((row) => {
        return (
            // TODO: Should we always display requests to their owners?
            (!isModuleFilterEnabled.value || Object.values(moduleFilter).includes(row.valuation_type)) &&
            // TODO: can we figure out a better logic to display requests needing attention, to users without module role?
            (!customerRefFilter ||
                row.customer_ref?.toLowerCase().includes(customerRefFilter.toLowerCase())) &&
            Object.entries(colFilters)
                .map(([key, value]) => {
                    if (!value) return true
                    // TODO: add support for date filter
                    const rowValue =
                        colFormatFns.value[key] !== undefined ? colFormatFns.value[key](row[key]) : row[key]
                    return rowValue?.toLowerCase().includes(value.toLowerCase())
                })
                .every(Boolean)
        )
    })
}

const showDuplicates = (row) => {
    const similarRefs = row.other_data.similar_requests.map((r) => r.valuation_request_ref)
    if (!similarRefs) requestList.valReqsFilter = null
    // requestList.valReqsFilter = [row.valuation_request_ref, ...similarRefs].join(',')
    router.push({ query: { valuation_request_refs: [row.valuation_request_ref, ...similarRefs].join(',') } })
}

const clearFilters = () => {
    requestList.customerRefFilter = ''
    tempCustomerRefFilter.value = ''
    // requestList.isUserRequests.value = true
    if (route.query.valuation_request_refs) router.push({ query: {} })
}

const generateReport = () => {
    console.log('Generating report')
    reportLoading.value = true
    return axios
        .get(`${utils.urlJoin(config.VALUATION_API_URL, 'billing_report')}`)
        .then((response) => {
            var now = new Date()
            var formattedDate = `${now.getFullYear()}-${('0' + now.getMonth() + 1).slice(
                -2
            )}-${('0' + now.getDate()).slice(-2)}-${('0' + now.getHours()).slice(
                -2
            )}-${('0' + now.getMinutes()).slice(-2)}`

            var blob = new Blob(['\ufeff', response.data])

            reportLink.value = URL.createObjectURL(blob)
            reportFileName.value = `billing-report-${formattedDate}`

            reportLoading.value = false
        })
        .catch((error) => {
            console.error(error)
            reportLoading.value = false
        })
}
</script>
<style lang="scss" scoped>
.sticky-first-column {
    /* bg color is important for th; just specify one */
    thead tr:first-child th:first-child {
        background-color: #eef1f4;
    }

    td:first-child {
        background-color: white;
    }
    th:first-child,
    td:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        border-right: 1px solid $border-color;
    }
}
</style>
<style lang="scss" scoped>
.request-table {
    border: 1px solid $border-color;
    border-radius: 4px;

    .header {
        // TODO: one of the header classes should cover this:
        font-weight: bold;
        background-color: #eef1f4;
        // color: white;
        border-right: 1px solid $border-color;
    }

    tr.filter-header {
        height: 30px;
        th {
            background-color: white;
            border-right: 1px solid $border-color;

            padding: 4px 8px;

            :deep(.q-field__control),
            :deep(.q-field__prepend) {
                height: 30px;
            }

            :deep(.q-field__control) {
                padding: 0 6px;
            }
        }
    }
}
</style>
