import URI from 'urijs'
import { config } from '../assets/config/config.js'
import enums from './enums.js'

const utils = {}
utils.iso_regions = {
    'BE-VLG': 'Flanders',
    'BE-WAL': 'Wallonia',
    'BE-BRU': 'Brussels',
}

utils.renovation_types = (renovation_names) => {
    // renovation_names: an array of renovation names
    // return an array of unique renovation types matching renovation_names
    const renovations = enums.allRenovations.filter((renovation) =>
        renovation_names.includes(renovation.name)
    )
    return [...new Set(renovations.map((renovation) => renovation.type))]
}

utils.getOptions = (key) => {
    // TODO: Shouldn't we be using full path?
    return config.find((item) => item.name === key).values
}

utils.clamp = (num, min, max) => Math.min(Math.max(num, min), max)

utils.objectFromArray = function (arr, fn) {
    return arr.reduce((acc, b) => {
        const [k, v] = fn.call(null, b)
        if (v === undefined) {
            acc[b] = k
        } else {
            acc[k] = v
        }
        return acc
    }, {})
}

utils.objectMap = function (obj, fn) {
    return Object.keys(obj).reduce((acc, k) => {
        acc[k] = fn.call(null, obj[k], k)
        return acc
    }, {})
}

utils.range = (start, end, step = 1) => {
    return Array.from({ length: Math.floor((end - start) / step) + 1 }, (_, i) => start + i * step)
}

utils.convertRange = (value, r1, r2) => {
    return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0]
}

utils.capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

utils.user_name = (user) => {
    let name = user
    if (typeof name === 'string') {
        if (name.includes('@')) {
            // Extract name from email
            return name.split('@')[0].split('.').map(utils.capitalizeFirstLetter).join(' ')
        } else {
            return name
        }
    } else {
        return name
    }
}

utils.computeEpcLabelFromScore = (score, region) => {
    const [label] = Object.entries(enums.epcMapping[region]).find(
        ([_label, range]) => score > range[0] && score <= range[1]
    )

    const color = utils.getEpcColorFromLabel(region, label)

    return {
        label,
        color,
    }
}

utils.computeEpcScoreFromLabel = (label, region) => {
    const epcRange = enums.epcMapping[region][label]
    return isFinite(epcRange[0]) ? epcRange[0] + 1 : epcRange[1]
}

utils.epc_percent_mapping = (region, epcScore) => {
    const data = {
        [enums.iso_regions.brussels]: {
            epcs: [660, 580, 500, 420, 345, 275, 210, 150, 95, 45, 0],
            percents: [7, 5, 7, 13, 21, 19, 15, 9, 3, 1, 0],
        },
        [enums.iso_regions.flanders]: {
            epcs: [1000, 900, 800, 700, 600, 500, 400, 300, 200, 100, 0, -100],
            percents: [3, 2, 3, 5, 8, 13, 14, 18, 20, 13, 0, 0],
        },
        [enums.iso_regions.wallonia]: {
            epcs: [900, 800, 700, 600, 510, 425, 340, 255, 170, 86, 65, 0, -20],
            percents: [5, 3, 5, 8, 14, 17, 18, 15, 10, 4, 1, 0, 0],
        },
    }
    if (epcScore !== undefined) {
        const bestEpc = data[region].epcs[data[region].epcs.length - 1]
        if (epcScore < bestEpc) {
            return 0
        }
        let percent = 100
        for (let i = 0; i < data[region].epcs.length - 1; i++) {
            const currentEpcVal = data[region].epcs[i]
            if (epcScore <= currentEpcVal) {
                percent -= data[region].percents[utils.clamp(i + 1, 0, data[region].percents.length - 1)]
            }
        }
        return utils.clamp(percent, 0, 100)
    } else {
        return data[region]
    }
}

utils.getEpcColorFromLabel = (region, label) => {
    if (!label || label === '?') return '#798DA6'

    return enums.epcColors[region][label]
}

utils.quantile_formatter = (value) => {
    if (value > 0.5) {
        return `top ${Math.ceil((1 - value) * 20) * 5}%`
    } else {
        return `bottom ${Math.ceil(value * 20) * 5}%`
    }
}

utils.isEmptyStr = (str) => {
    return !str || !str.replace(/\s/g, '').length
}

utils.urlJoin = (baseUrl, paths) => {
    // TODO: prevent calls to urlJoin when baseUrl is not set
    if (!baseUrl) {
        // TODO: Figure out a way to catch and handle TypeError exceptions globally
        throw new TypeError('Invalid parameters for urlJoin function.')
    }

    // make paths an array:
    if (typeof paths === 'string') {
        paths = [baseUrl, paths]
    } else {
        paths = [baseUrl, ...paths]
    }

    // remove null values from paths (???)
    paths = paths.filter((path) => path !== null && path !== undefined)

    const hasTrailingSlash = paths.length > 0 && paths[paths.length - 1].endsWith('/')

    // remove '/' from start and end of each path:
    paths = paths.map((path) => path.replace(/^\/|\/$/g, '')).filter((path) => path !== '')

    // join all path elements with '/'
    const fullPath = (baseUrl.startsWith('http') ? '' : '/') + paths.join('/') + (hasTrailingSlash ? '/' : '')

    try {
        return new URI(fullPath).toString()
    } catch (e) {
        throw new Error('Invalid parameters for urlJoin function.')
    }
}

utils.val_urls = (conf) => {
    return {
        request: utils.urlJoin(conf.VALUATION_API_URL, 'request'),
        request_ref: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, ['request', valuation_request_ref]),
        request_ref_status: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, ['request', valuation_request_ref, 'status']),
        request_ref_transaction_value: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, ['request', valuation_request_ref, 'transaction_value']),
        request_ref_owner: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, ['request', valuation_request_ref, 'owner']),
        request_ref_valuer: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, ['request', valuation_request_ref, 'valuer']),
        request_ref_type: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, ['request', valuation_request_ref, 'type']),
        request_ref_borrower: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, ['request', valuation_request_ref, 'borrower']),
    }
}

utils.getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) {
        return parts.pop().split(';').shift()
    }
    return undefined
}

utils.isoDatetime = (aDate) => (aDate ?? new Date()).toISOString().slice(0, 19).replace('T', ' ')

utils.parseFUDate = (dateStr) => {
    if (dateStr === null || dateStr === undefined) return null
    // If dateStr is in iso format, just pass it to the Date constructor
    if (dateStr.includes('T')) return new Date(dateStr)
    // "Weird, everything used to work, back in october, november and december…"
    const parts = dateStr.split(' ')
    const dateParts = parts[0].split('-')
    const timeParts = parts[1].split(':')

    const year = parseInt(dateParts[0], 10)
    const month = parseInt(dateParts[1], 10) - 1 // JavaScript counts months from 0
    const day = parseInt(dateParts[2], 10)
    const hour = parseInt(timeParts[0], 10)
    const minute = parseInt(timeParts[1], 10)
    const second = parseInt(timeParts[2], 10)

    return new Date(year, month, day, hour, minute, second)
}

utils.arrayToggle = (array, value) => {
    var index = array.indexOf(value)

    if (index === -1) {
        return [...array, value]
    } else {
        return array.filter((el) => el !== value)
    }
}

utils.random_string = (length) => {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    let values = new Uint32Array(length)
    window.crypto.getRandomValues(values)
    for (let i = 0; i < length; i++) {
        result += charset[values[i] % charset.length]
    }
    return result
}

// https://stackoverflow.com/a/3464346 but modified because it made some mistakes or didn't conform to what we're trying to achieve
utils.dateDiffInBusinessDays = (dDate1, dDate2) => {
    var iWeeks,
        iDateDiff,
        iAdjust = 0
    if (dDate2 < dDate1) return -1 // error code if dates transposed
    var iWeekday1 = dDate1.getDay() // day of week
    var iWeekday2 = dDate2.getDay()
    iWeekday1 = iWeekday1 == 0 ? 7 : iWeekday1 // change Sunday from 0 to 7
    iWeekday2 = iWeekday2 == 0 ? 7 : iWeekday2
    if (iWeekday1 > 5) iAdjust = 1
    iWeekday1 = iWeekday1 > 5 ? 5 : iWeekday1 // only count weekdays
    iWeekday2 = iWeekday2 > 5 ? 5 : iWeekday2

    // calculate differnece in weeks (1000mS * 60sec * 60min * 24hrs * 7 days = 604800000)
    iWeeks = Math.floor((dDate2.getTime() - dDate1.getTime()) / 604800000)

    if (iWeekday1 <= iWeekday2) {
        iDateDiff = iWeeks * 5 + (iWeekday2 - iWeekday1)
    } else {
        iDateDiff = (iWeeks + 1) * 5 - (iWeekday1 - iWeekday2)
    }

    iDateDiff -= iAdjust

    return iDateDiff
}

utils.formatAddress = (address) => {
    if (!address)
        return {
            firstLine: '',
            secondLine: '',
        }
    const { streetname, streetnumber, postalcode, municipality, boxnumber } = address
    const box_appendix = boxnumber ? ` b ${boxnumber}` : ''
    return {
        firstLine: `${streetname} ${streetnumber}${box_appendix}`,
        secondLine: `${postalcode} ${municipality}`,
    }
}

utils.full_address = (address, features) => {
    if (!address) return ''
    let building_type = features ? features.f_building_type : null
    if (building_type === 'house' || building_type === 'apartment' || !building_type) {
        if (!address.streetname) return ''
        let box_appendix = address.boxnumber ? ` b ${address.boxnumber}` : ''
        if (address.postalcode !== null) {
            return `${address.streetname} ${address.streetnumber}${box_appendix}, ${address.postalcode} ${address.municipality}`
        }
    } else {
        let parcel_ids = features ? features.parcel_ids : null
        if (parcel_ids) {
            return parcel_ids[0]
        }
    }
    return ''
}

utils.via_address = (address, features) => {
    if (!address) return ''
    let building_type = features ? features.f_building_type : null
    if (building_type !== 'house' && building_type !== 'apartment' && building_type !== null) {
        return utils.full_address(address)
    }
    return ''
}

utils.short_address = (address, features) => {
    let building_type = features ? features.f_building_type : null
    if (building_type === 'house' || building_type === 'apartment' || !building_type) {
        let box_appendix = address.boxnumber ? ` b ${address.boxnumber}` : ''
        if (address.postalcode) {
            return `${address.streetname} ${address.streetnumber}${box_appendix}, ${address.postalcode}`
        }
    } else {
        let parcel_ids = features ? features.parcel_ids : null
        if (parcel_ids) {
            return parcel_ids[0]
        }
    }
    return ''
}

//https://dev.to/gladchinda/javascript-tip-whatis-a-better-typeof-3g0o
utils.whatis = (value) => {
    return Object.prototype.toString
        .call(value)
        .replace(/^\[object\s+([a-z]+)\]$/i, '$1')
        .toLowerCase()
}

utils.is_apartment = (f_building_type) => ['apartment', 'new_apartment'].includes(f_building_type)
utils.is_house = (f_building_type) => ['house', 'new_house'].includes(f_building_type)
utils.is_building = (f_building_type) =>
    utils.is_house(f_building_type) || utils.is_apartment(f_building_type)
utils.is_new = (f_building_type) => ['new_house', 'new_apartment'].includes(f_building_type)
utils.is_plot = (f_building_type) => ['construction_plot'].includes(f_building_type)

utils.forced_sale_value = (value) => {
    const x = value / 1000000
    let y
    if (x < 0.2) {
        y = 1 - x
    } else if (x < 0.4) {
        y = 0.9 - x / 2
    } else {
        y = 0.8 - x / 4
    }
    // Above lines are the best way we found to replace numpy.interp for more than just one segment
    // x vector is [100000, 200000, 400000, 800000]
    // y vector is [0.9, 0.8, 0.7, 0.6]
    // We divide by 1MIL before doing operations to avoid having too many zeroes lying around

    return Math.round((y * value) / 1000) * 1000
}

utils.rolesToList = (userRoles) => {
    const rolesList = []
    for (const [mod, roles] of Object.entries(userRoles)) {
        for (const role of roles) {
            rolesList.push(`${mod}:${role}`)
        }
    }
    rolesList.sort()
    return rolesList
}

utils.listToRoles = (rolesList) => {
    const userRoles = {}
    for (const userRole of rolesList) {
        const [mod, role] = userRole.split(':')
        if (mod in userRoles) {
            userRoles[mod].push(role)
        } else {
            userRoles[mod] = [role]
        }
    }
    return userRoles
}

utils.containsRole = (rolesObj, roleOrList, module) => {
    // Can take a list of roles, or a list of [role, module] pairs

    if (typeof roleOrList !== 'string')
        return roleOrList.some((r) =>
            typeof r === 'string'
                ? utils.containsRole(rolesObj, r, module)
                : utils.containsRole(rolesObj, r[0], r[1])
        )

    const role = roleOrList

    if (!module) {
        for (const roles of Object.values(rolesObj)) {
            if (roles.includes(role)) {
                return true
            }
        }
        return false
    }
    return rolesObj['*']?.includes(role) || rolesObj[module]?.includes(role)
}

// https://stackoverflow.com/a/67994693
utils.extractFilenameFromHeader = (disposition) => {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i

    let fileName = null
    if (utf8FilenameRegex.test(disposition)) {
        fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1])
    } else {
        // prevent ReDos attacks by anchoring the ascii regex to string start and
        //  slicing off everything before 'filename='
        const filenameStart = disposition.toLowerCase().indexOf('filename=')
        if (filenameStart >= 0) {
            const partialDisposition = disposition.slice(filenameStart)
            const matches = asciiFilenameRegex.exec(partialDisposition)
            if (matches != null && matches[2]) {
                fileName = matches[2]
            }
        }
    }
    return fileName
}

utils.unique = (arrOrObject, property = undefined) => {
    const arr = property ? (arrOrObject || {}).map((obj) => obj[property]) : arrOrObject || []
    return new Set(arr)
}

export default utils
